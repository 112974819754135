<template>
  <v-row class="mt-2 mx-n3">
    <v-col
      v-for="(item, index) in scoresCards"
      :key="index"
      cols="12"
      md="auto"
      sm="6"
    >
      <stats-card
        :loading="item.loading"
        :skeleton="item.skeleton"
        :name="item.name"
        :value="item.value"
        class="mt-0"
      />
    </v-col>
  </v-row>
</template>
<script>
import StatsCard from "../../components/shared/stats_card.vue"

export default {
  name: "ScoreCards",
  components: { StatsCard },
  props: {
    score: { type: Object, default: () => null },
    quiz: { type: Object, required: true },
    teacherMode: { type: Boolean, default: false },
  },
  computed: {
    grade() { return this.score?.pretty_grade },

    scoresCards() {

      return [
        {
          name: this.$t("live.score"),
          value: `${this.grade}/${this.quiz.grade_scale}`,
          visible: this.showScore,
          skeleton: this.grade == null || isNaN(this.grade),
          loading: this.score?.answers_pending_count > 0,
        },
        {
          name: this.$t("live.correction.answered"),
          value: `${this.score?.answered_questions_count_cache}/${this.quiz?.questions_count}`,
          visible: true,
          skeleton: !this.score || !this.quiz,
        },
        {
          name: this.$t("live.correctAnswers"),
          value: `${Math.round(this.score?.correct_answers_count)}/${this.score?.answered_questions_count_cache}`,
          visible: this.showScore,
          skeleton: !this.score,
        },
        {
          name: this.$t("live.correction.cheat"),
          value: this.score?.cheating_attempt_count,
          visible: !this.paperQuiz && this.currentUser?.teacher,
          skeleton: !this.score || !this.currentUser,
        },
        {
          name: this.$t(
            "evalmee.home.teacher.quizTeacherScores.table.duration.label"),
          value: this.studentExamDuration,
          visible: !this.paperQuiz && this.currentUser?.teacher &&
            this.studentExamDuration,
          skeleton: !this.score || !this.currentUser,
        },

      ].filter(e => e.visible)
    },

    showScore() {
      return (this.teacherMode || this.quiz?.show_scores) &&
        (this.score.in_over_state || this.quiz.live_scores)
    },

  },
}
</script>
